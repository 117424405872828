import React, { useEffect } from 'react'

function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    

    return (
        <div className='pt-[100px] pb-[100px] font-urbanist sm:px-36 px-4'>
            <div className='sm:mt-[72px]'>
                <div className='text-[18px] sm:text-[36px] leading-8 font-medium'>Privacy Policy</div>
                <div className='text-[10px] sm:text-[16px] leading-3 sm:leading-6 tracking-wide font-normal sm:mt-[72px] mt-4 text-left'>At Solwyz Technologies, we are committed to protecting the privacy and security of our users. This privacy statement explains how we collect, use, and protect personal data.</div>

                <div className='text-[10px] sm:text-[18px] leading-3 sm:leading-6 tracking-wide font-semibold sm:mt-10 mt-4 text-left'>What Personal Data We Collect :</div>
                <div className='text-left sm:mt-4 mt-2 sm:text-[16px] text-[10px]'>We collect personal data that you voluntarily provide to us, such as:
                    <ul className='list-disc sm:px-5 px-4 sm:mt-2'>
                        <li> Contact information (name, email address, phone number)</li>
                        <li> Business information (company name, address, industry)</li>
                        <li> Communication preferences</li>
                        <li> Usage data (website interactions, browsing history)</li>
                    </ul>
                </div>

                <div className='text-[10px] sm:text-[18px] leading-3 sm:leading-6 tracking-wide font-semibold sm:mt-10 mt-4 text-left'>How We Use Personal Data</div>
                <div className='text-left sm:mt-4 mt-2 sm:text-[16px] text-[10px]'>We use personal data to:
                    <ul className='list-disc sm:px-5 px-4 sm:mt-2'>
                        <li> Respond to inquiries and provide customer support</li>
                        <li> Send newsletters, promotional materials, and updates</li>
                        <li> Analyze website usage and improve user experience</li>
                        <li> Comply with legal obligations and regulatory requirements</li>
                    </ul>
                </div>

                <div className='text-[10px] sm:text-[18px] leading-3 sm:leading-6 tracking-wide font-semibold sm:mt-10 mt-4 text-left'>How We Protect Personal Data</div>
                <div className='text-left sm:mt-4 mt-2 sm:text-[16px] text-[10px]'>We implement robust security measures to protect personal data from unauthorized access, disclosure, alteration, or destruction. These measures include:
                    <ul className='list-disc sm:px-5 px-4 sm:mt-2'>
                        <li> Encryption and secure socket layer (SSL) technology</li>
                        <li> Firewalls and intrusion detection systems</li>
                        <li> Access controls and authentication protocols</li>
                        <li> Regular security audits and vulnerability assessments</li>
                    </ul>
                </div>

                <div className='text-[10px] sm:text-[18px] leading-3 sm:leading-6 tracking-wide font-semibold sm:mt-10 mt-4 text-left'>Sharing Personal Data</div>
                <div className='text-left sm:mt-4 mt-2 sm:text-[16px] text-[10px]'>We do not share personal data with third-party companies, organizations, or individuals, except in the following circumstances:
                    <ul className='list-disc sm:px-5 px-4 sm:mt-2'>
                        <li> With your consent or at your request</li>
                        <li> To comply with legal obligations or regulatory requirements</li>
                        <li> To protect our rights, property, or safety, or that of our users or the public</li>
                    </ul>
                </div>

                <div className='text-[10px] sm:text-[18px] leading-3 sm:leading-6 tracking-wide font-semibold sm:mt-10 mt-4 text-left'>Your Rights</div>
                <div className='text-left sm:mt-4 mt-2 sm:text-[16px] text-[10px]'>You have the right to:
                    <ul className='list-disc sm:px-5 px-4 sm:mt-2'>
                        <li> Access and correct your personal data</li>
                        <li> Object to or restrict processing of your personal data</li>
                        <li> Request deletion of your personal data</li>
                        <li> Lodge a complaint with a supervisory authority</li>
                    </ul>
                </div>

                <div className='text-[10px] sm:text-[18px] leading-3 sm:leading-6 tracking-wide font-semibold sm:mt-10 mt-4 text-left'>Changes to This Privacy Statement</div>
                <div className='text-left sm:mt-4 mt-2 sm:text-[16px] text-[10px]'>We reserve the right to update or modify this privacy statement at any time. Changes will be posted on this page, and we will notify you through our website or email.</div>

                <div className='text-[10px] sm:text-[18px] leading-3 sm:leading-6 tracking-wide font-semibold sm:mt-10 mt-4 text-left'>Contact Us</div>
                <div className='text-left sm:mt-4 mt-2 sm:text-[16px] text-[10px]'>If you have any questions, concerns, or requests regarding this privacy statement or our handling of personal data, please contact us at connect@solwyz.com.</div>

                <div className='text-left sm:mt-4 mt-2 sm:text-[16px] text-[10px]'>By using Solwyz Technologies' website or services, you acknowledge that you have read, understood, and agree to be bound by this privacy statement.</div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
